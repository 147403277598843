import {request} from "../request/request";

const me = {
  getOne: () => request.read('/me', null, true),
  getVerify: () => request.read('/me/verify', null, true),
  putOne: (data) => request.update('/me', data, true),
  putPassword: (data) => request.update('/me/password', data, true),
  putAvatar: (data) => request.update('/me/avatar', data, true),
  postRequest: (data) => request.update('/me/request', data, true),
};

export {me};