import "react-hint/css/index.css";
import React, {useEffect} from 'react';
import {Container, Row} from "react-bootstrap";
import Auth from "./layouts/Auth";
import Guest from "./layouts/Guest";
import ReCaptcha from "./components/captcha/ReCaptcha";
import {useDispatch, useSelector} from "react-redux";
import {syncProfile} from "./storage/redux/slices/user";
import Loader from "./components/loader/Loader";
import FlashMessages from "./components/flash-message/FlashMessages";

export default function App() {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    ReCaptcha.connect();
    dispatch(syncProfile());
  }, [dispatch]);

  return (
    <Container fluid>
      <Row className={`d-flex align-items-stretch main ${user.isGuest ? 'guest' : 'auth'}`}>
        <FlashMessages/>
        {user.loaded ? renderLayout() : <Loader/>}
      </Row>
    </Container>
  );

  function renderLayout() {
    return React.createElement(user.isGuest ? Guest : Auth);
  }
}
