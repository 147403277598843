export default class ReCaptcha {
  static SITE_KEY = '6LdhsjIcAAAAAKe2L5wn3Lvp6TbpURFNRXDV4eTS';

  static connect() {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + this.SITE_KEY;
    script.async = true;
    document.body.appendChild(script);
  }

  static execute(action, callable) {
    grecaptcha.ready(() => { // eslint-disable-line
      grecaptcha.execute(this.SITE_KEY, {action: action}) // eslint-disable-line
        .then(callable);
    });
  }
}